<template>
    <div class="content-body">
        <h1 class="title">找不到您输入的网址</h1>
    </div>
</template>
<script>
export default {
  name: "NoContent",
  mounted() {
    document.title = '404'
  }
}
</script>
<style lang="less">
.content-body{
    width:7.5rem;
    margin: 0 auto;
    height:100%;
    text-align: center;
    font-size: .18rem;

    .title{
        position: relative;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
}
</style>
